import { ReactComponent as InfoIcon } from "../../../../assets/info.svg";

import { ReactComponent as AhdistusIcon } from "../../../../assets/emotions/ahdistus.svg";
import { ReactComponent as MukavuusIcon } from "../../../../assets/emotions/mukavuus.svg";
import { ReactComponent as PaineIcon } from "../../../../assets/emotions/paine.svg";
import { ReactComponent as PakoIcon } from "../../../../assets/emotions/pako.svg";
import { ReactComponent as StressiIcon } from "../../../../assets/emotions/stressi.svg";
import { ReactComponent as TehoIcon } from "../../../../assets/emotions/teho.svg";
import { ReactComponent as TurtaIcon } from "../../../../assets/emotions/turta.svg";
import { ReactComponent as TyonIloIcon } from "../../../../assets/emotions/tyon-ilo.svg";

export const getIconByOrdinal = (
  ordinal: number
): React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
> | null => {
  switch (ordinal) {
    case 1:
      return TurtaIcon;
    case 2:
      return PakoIcon;
    case 3:
      return AhdistusIcon;
    case 4:
      return StressiIcon;
    case 5:
      return PaineIcon;
    case 6:
      return TehoIcon;
    case 7:
      return TyonIloIcon;
    case 8:
      return MukavuusIcon;

    default:
      return InfoIcon;
  }
};
