import React, { useState, useEffect } from "react";
import { Page } from "../../App";
import { useTranslation } from "react-i18next";
import fetchGraphQL from "../../utils/fetchGraphQL";
import Colors from "../../constants/colors";
import ReactMarkdown from "react-markdown";
import { EmotionIcon } from "../../components/Icons";
import { getLocaleValue } from "../../utils/localization";

const detailStyles = {
  container: {
    flex: 1,
    backgroundColor: Colors.light.screenBackground,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    margin: 16,
  },
  headerContainer: {
    backgroundColor: "transparent",
    paddingTop: 16,
    paddingBottom: 16,
  },
  emotionalStateText: {
    fontSize: "3em",
    letterSpacing: 0.3,
    color: "#000",
    fontWeight: "bold",
  },
  description: {
    fontSize: 16,
    color: "#000",
    marginTop: 8,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  emotionalCategoryContainer: {
    textTransform: "uppercase",
    color: "#000",

    backgroundColor: "rgba(0, 0, 0, 0.09)",
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 100,
    marginRight: -8,
    marginTop: -8,
  },
  emotionalCategoryText: {
    textTransform: "uppercase",
    color: "#000",

    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 100,
    fontSize: 11,
    lineHeight: "14px",
    letterSpacing: 0.5,
    fontWeight: "bold",
  },
  titleContainer: {
    backgroundColor: "transparent",
    flexDirection: "row",
  },
  icon: {
    height: 28,
    width: 28,
    marginRight: 16,
    marginTop: 4,
  },
  button: {
    backgroundColor: Colors.light.background,
    padding: 16,
    marginTop: 40,
    borderRadius: 8,
    alignSelf: "center",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  buttonText: {
    flex: 0.9,
    fontSize: 14,
    fontWeight: "500",
  },
  buttonIcon: {
    flex: 0.1,
    alignSelf: "center",
    marginLeft: 16,
  },
  backgroundIcon: {
    opacity: 0.06,
    position: "absolute",
    width: "130%",
    height: "130%",
    left: "-20%",
  },
  descriptionContainer: {
    backgroundColor: "#fffffff0",
    marginTop: 32,
    width: "100%",
    padding: 24,
    height: "100%",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  },
};

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: 48,
        margin: 24,
        border: "1px solid #DFE0EB",
        borderRadius: 8,
      }}
    >
      <div style={detailStyles.headerContainer}>
        <div style={detailStyles.titleContainer}>
          <div style={{ flex: 1 }}>
            <div style={detailStyles.emotionalStateText}>{t("emotion.title")}</div>
          </div>
        </div>
      </div>

      <div style={{ maxWidth: 550 }}>
        <p>
          <b>{t("emotion.desc-1")}</b>
        </p>
        <p>{t("emotion.desc-2")}</p>
        <p>{t("emotion.desc-3")} </p>
        <p>{t("emotion.desc-4")}</p>
        <p>{t("emotion.desc-5")}</p>
        <p>{t("emotion.desc-6")}</p>
      </div>
    </div>
  );
};

const Detail = ({ id }) => {
  const [emotionalState, setEmotionalStates] = useState<any>();
  const emotionalStateId = id;

  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    let isMounted = true;
    fetchGraphQL(
      `
      query($id: Int!){
        emotionalStates(where: {id: {_eq: $id} }, order_by: {ordinal: desc}, distinct_on: ordinal) {
          name
          icon
          learnDescription
          learnLongDescription
          learnLeaderDescription
          ordinal
          color
          emotionalStateCategory {
            name
          }
        }
      }
  `,
      { id: emotionalStateId }
    )
      .then((response) => {
        // Avoid updating state if the component unmounted before the fetch completes

        if (!isMounted) {
          return;
        }
        const emotions = response.data.emotionalStates.map((state: any): any => ({
          name: getLocaleValue(state.name, language),
          icon: state.icon,
          description: getLocaleValue(state.learnDescription, language),
          longDescription: getLocaleValue(state.learnLongDescription, language),
          leaderDescription: state.learnLeaderDescription && getLocaleValue(state.learnLeaderDescription, language),
          ordinal: state.ordinal,
          color: state.color,
          category: getLocaleValue(state.emotionalStateCategory.name, language),
        }));
        setEmotionalStates(emotions[0]);
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      isMounted = false;
    };
  }, [emotionalStateId, language]);

  if (!emotionalState) return null;

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: 48,
        margin: 24,
        border: "1px solid #DFE0EB",
        borderRadius: 8,
        flex: 2,
      }}
    >
      <div style={detailStyles.headerContainer}>
        <div style={detailStyles.titleContainer}>
          <div style={{ flex: 1 }}>
            <div style={detailStyles.emotionalStateText}>{emotionalState.name}</div>
            <div style={detailStyles.description}>{emotionalState.description}</div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
          margin: -36,
        }}
      >
        <div style={{ flex: 1, maxWidth: 500, minWidth: 400, padding: 36 }}>
          <ReactMarkdown source={emotionalState.longDescription} />
        </div>
        <div style={{ flex: 1, maxWidth: 500, minWidth: 400, padding: 36 }}>
          <ReactMarkdown source={emotionalState.leaderDescription} />
        </div>
      </div>
    </div>
  );
};

const Emotions = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [emotionalStates, setEmotionalStates] = useState<any[]>([]);
  const [selected, setSelected] = useState<number>();

  useEffect(() => {
    let isMounted = true;
    fetchGraphQL(`
        query {
          emotionalStates(order_by: {ordinal: desc}, distinct_on: ordinal) {
            id
            name
            icon
            learnDescription
            ordinal
            color
            emotionalStateCategory {
              name
            }
          }
        }
    `)
      .then((response) => {
        // Avoid updating state if the component unmounted before the fetch completes

        if (!isMounted) {
          return;
        }
        const emotions = response.data.emotionalStates.map((state: any): any => ({
          id: state.id,
          name: getLocaleValue(state.name, language),
          icon: state.icon,
          description: getLocaleValue(state.learnDescription, language),
          ordinal: state.ordinal,
          color: state.color,
          category: getLocaleValue(state.emotionalStateCategory.name, language),
        }));
        setEmotionalStates(emotions);
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      isMounted = false;
    };
  }, [language]);

  const renderEmotionalState = ({ item }) => {
    return (
      <div
        key={item.ordinal}
        style={Object.assign(
          {},
          styles.box,
          { backgroundColor: item.color },
          selected === item.id ? styles.selected : {}
        )}
        onClick={() => {
          setSelected(item.id);
        }}
      >
        <div style={styles.header}>
          <div></div>
          <div style={styles.emotionalCategoryContainer}>
            <div
              style={{
                ...styles.emotionalCategoryText,
                ...{ color: item.color, alignSelf: "flex-end" },
              }}
            >
              {item.category}
            </div>
          </div>
        </div>

        <div style={styles.titleContainer}>
          <div style={{ flex: 1 }}>
            <div style={styles.emotionalStateText}>
              <EmotionIcon icon={item.icon} style={styles.backgroundIcon} fill="#fff" />
              {item.name}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Page>
      <div style={{ display: "flex", padding: 60 }}>
        {(selected && <Detail id={selected} />) || <Welcome />}
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            height: "100%",
            marginTop: 24,
          }}
        >
          <h2 style={{ paddingLeft: 8 }}>{t("Learn to lead")}</h2>
          {emotionalStates.map((emotionalState) => renderEmotionalState({ item: emotionalState }))}
        </div>
      </div>
    </Page>
  );
};

const styles = {
  container: {
    display: "flex",
    flex: 1,
    backgroundColor: Colors.light.screenBackground,
  },
  title: {
    display: "flex",
    fontSize: 24,
    fontWeight: "bold",
    margin: 16,
  },
  list: {
    display: "flex",
    flex: 1,
    padding: 16,
  },
  box: {
    display: "flex",
    flexDirection: "column",
    margin: 10,
    padding: 8,
    borderRadius: 15,
    width: 250,
    cursor: "pointer",
    boxSizing: "border-box",
    opacity: 0.7,
  },
  selected: {
    opacity: 1,
  },
  emotionalStateText: {
    fontSize: 24,
    letterSpacing: 0.3,
    color: Colors.light.colorOnPrimary,
    fontWeight: "bold",
  },
  description: {
    fontSize: 16,
    color: Colors.light.colorOnPrimary,
    opacity: 0.8,
    marginTop: 8,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  emotionalCategoryContainer: {
    textTransform: "uppercase",
    color: Colors.light.colorOnPrimary,
    backgroundColor: "white",
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 100,
  },
  emotionalCategoryText: {
    textTransform: "uppercase",
    color: Colors.light.colorOnPrimary,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 100,
    fontSize: "11px",
    lineHeight: "14px",
    letterSpacing: 0.5,
    fontWeight: "bold",
  },
  titleContainer: {
    backgroundColor: "transparent",
    flexDirection: "row",
    marginTop: 8,
    marginBottom: 16,
  },
  icon: {
    height: 28,
    width: 28,
  },
  button: {
    backgroundColor: Colors.light.background,
    padding: 16,
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,
    borderRadius: 8,
    alignSelf: "center",
    width: "calc(100% - 32px)",
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
  },
  buttonText: {
    flex: 0.9,
    fontSize: 14,
    fontWeight: "500",
  },
  buttonIcon: {
    flex: 0.1,
    alignSelf: "center",
    marginLeft: 16,
  },
  backgroundIcon: {
    height: 20,
    width: 20,
    marginRight: 16,
    marginLeft: 8,
  },
};

export default Emotions;
