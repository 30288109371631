import React, { FunctionComponent, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { ReactComponent as LoadingSpinner } from "../../assets/loading.svg";
import { getLocaleValue } from "../../utils/localization";
import { API, SelectProps } from "../../types";

type Props = SelectProps<API.Theme>;
interface ThemeData {
  themes: API.Theme[];
}

const query = gql`
  query ThemesQuery {
    themes {
      id
      name
    }
  }
`;

const ThemeSelect: FunctionComponent<Props> = (props) => {
  const { setValue } = props;
  const { data, error } = useQuery<ThemeData>(query);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Propagate default value to parent as soon as the themes are loaded
    setValue(data?.themes[0]);
  }, [data, setValue]);

  if (error) {
    return <p>{t("errorFetchingThemes")}</p>;
  }

  if (data) {
    return (
      <Select<any>
        styles={{
          control: (provided: any) => ({
            ...provided,
            width: 200,
            color: "#4B506DB3",
            backgroundColor: "#FCFDFE",
          }),
        }}
        getOptionLabel={(option) => {
          const theme = data.themes.find((theme: any) => theme.id === option.id);
          if (theme) {
            return getLocaleValue(theme.name, i18n.language);
          }
          return "undefined";
        }}
        getOptionValue={(option) => option.id}
        onChange={setValue}
        defaultValue={data?.themes[0]}
        options={data?.themes}
      />
    );
  }

  return <LoadingSpinner />;
};

export default ThemeSelect;
