import styled from "styled-components";

export const H1 = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #252733;
`;

export const MutedText = styled.p`
  opacity: 0.5;
`;

export const Text = styled.p``;
