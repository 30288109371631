import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { I18n as AmplifyI18n } from "aws-amplify";

import { saveUserLanguage } from "../utils/i18n";

import "./LanguageChanger.css";

type Props = {
  className?: string;
};

const LanguageChanger: FunctionComponent<Props> = ({ className }) => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    AmplifyI18n.setLanguage(lang);
    saveUserLanguage(lang);
  };

  return (
    <div className={["language-changer", className ?? ""].join(" ")}>
      <button className={i18n.language === "fi" ? "active" : ""} onClick={() => changeLanguage("fi")}>
        Suomeksi
      </button>
      <button className={i18n.language === "en" ? "active" : ""} onClick={() => changeLanguage("en")}>
        In English
      </button>
    </div>
  );
};

export default LanguageChanger;
