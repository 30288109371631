import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { getLocaleValue } from "../../utils/localization";
import { API, SelectProps } from "../../types";

export interface ReportingGroupsData {
  reportingGroups: API.ReportingGroup[];
}

type Props = {
  setValue: SelectProps<API.ReportingGroup>["setValue"];
  data: ReportingGroupsData | undefined;
};

const GroupSelect: FunctionComponent<Props> = ({ setValue, data }) => {
  const { t, i18n } = useTranslation();

  const wholeOrganisation = {
    id: null,
    name: t("whole organisation"),
    label: t("whole organisation"),
  };

  useEffect(() => {
    setValue(wholeOrganisation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = [
    wholeOrganisation,
    ...(data?.reportingGroups ?? [])
      .map((group) => ({
        id: group.id,
        name: group.name,
        sortedValue: getLocaleValue(group.name, i18n.language),
      }))
      .sort((a, b) => a.sortedValue.localeCompare(b.sortedValue)),
  ];

  // Had t o put the value as any and then cast it in the onChange as it seemed to have a fit when the type was almost same as the default one :D
  return (
    <Select<any>
      styles={{
        control: (provided: any) => ({
          ...provided,
          width: 200,
          color: "#4B506DB3",
          backgroundColor: "#FCFDFE",
        }),
      }}
      getOptionLabel={(option) => {
        if (option.id === null) {
          return wholeOrganisation.name;
        } else {
          const reportingGroup = data?.reportingGroups.find((group) => group.id === option.id);
          if (reportingGroup) {
            return getLocaleValue(reportingGroup.name, i18n.language);
          }
          return "";
        }
      }}
      getOptionValue={(option) => option.id}
      onChange={setValue}
      defaultValue={wholeOrganisation}
      options={options}
    />
  );
};

export default GroupSelect;
