import { TeamGraphDatapoint } from "./Graphs/TeamGraph";
import { API, DriverCategory, OrgStatistics, ResultDriver } from "../../types";

/**
 * @param emotionalStates
 * @param historyData
 * @returns
 */
export const getResultsFromHistoryData = (
  emotionalStates: API.EmotionalState[] = [],
  historyData?: API.HistoryData
) => {
  if (!historyData) return [];

  const results: TeamGraphDatapoint[][] = historyData.map((weekData, index) => {
    let dataPoints: TeamGraphDatapoint[];

    if (weekData.results?.length <= 0) {
      dataPoints = [
        {
          delta: index,
          weekNumber: weekData.week.weekNumber,
          weight: 0,
        },
      ];
    } else {
      dataPoints = weekData.results.map((v) => {
        const emotionalStateCategory = emotionalStates.find(
          (emotionalState) => emotionalState.ordinal === v.emotionalStateOrdinal
        );
        const dataPoint: TeamGraphDatapoint = {
          delta: index,
          emotionalStateCategory,
          weekNumber: weekData.week.weekNumber,
          weight: v.weight,
        };
        return dataPoint;
      });
    }

    return dataPoints;
  });
  return results;
};

/**
 * Parses reporting group history query data into format suitable for the drivers component.
 * Adds emotionalStateCategory data to driver category and transforms driver results from arrays to object array
 * @param categories
 * @param drivers
 * @param pickedDrivers
 * @param weekNumber
 * @returns
 */
export const getDriversFromHistoryData = ({
  emotionalStateCategories,
  drivers,
  historyData,
  weekNumber,
}: {
  emotionalStateCategories?: API.EmotionalStateCategory[];
  drivers?: API.Driver[];
  historyData?: API.HistoryData;
  weekNumber?: string;
}): DriverCategory[] | undefined => {
  if (!emotionalStateCategories || !drivers || !historyData || !weekNumber) return undefined;

  const selectedWeeksData = historyData.find((weekData) => weekData?.week?.fromDate === weekNumber);

  if (!selectedWeeksData) return undefined;

  // Map trough one week's driver categories and transform driver results into object format from the arrays
  const driverCategories = selectedWeeksData.drivers.map((driverCategory) => {
    const category = emotionalStateCategories.find((c) => c.id === driverCategory?.categoryId);

    const data: ResultDriver[] = driverCategory.driverIds.map((id, index) => {
      const driver = drivers.find((d) => d.id === id);

      const value: ResultDriver = {
        driver: driver!,
        percentage: driverCategory.driverPercents[index],
        isSupporting: driverCategory.isSupporting[index],
        descriptions: driverCategory.descriptions[index],
      };
      return value;
    });

    // Return each driver category data with the drivers selected for each category
    return {
      category: category!,
      categoryId: driverCategory.categoryId,
      descriptions: driverCategory.descriptions,
      driverIds: driverCategory.driverIds,
      driverPercents: driverCategory.driverPercents,
      isSupporting: driverCategory.isSupporting,
      weekNumber: [driverCategory.weekNumber],
      data,
    };
  });

  return driverCategories;
};

export const getStatisticsFromHistoryData = (
  historyData?: API.HistoryData,
  weekNumber?: string
): OrgStatistics | undefined => {
  if (!weekNumber || !historyData) return undefined;

  const selectedWeeksData = historyData.find((weekData) => weekData?.week?.fromDate === weekNumber);

  const statistics = selectedWeeksData?.statistics;
  return statistics;
};
