import { useQuery } from "@apollo/client";
import { format, sub } from "date-fns";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import "./overview.css";

import { Page } from "../../App";
import GroupSelect, { ReportingGroupsData } from "./GroupSelect";
import ThemeSelect from "./ThemeSelect";
import Graph from "./Graph";
import Drivers from "./Drivers/Drivers";
import Stuckages from "./Stuckages";

import { ORGANIZATION_HISTORY_QUERY, REPORTING_GROUP_HISTORY_QUERY, REPORTING_GROUP_QUERY } from "./queries";

import { getDriversFromHistoryData, getStatisticsFromHistoryData } from "./historyDataParsers";

import { getLocaleValue } from "../../utils/localization";

import { API } from "../../types";
import { H1 } from "../../components/Text";

const Overview = () => {
  const { t, i18n } = useTranslation();

  const [selectedWeekNumber, setSelectedWeekNumber] = React.useState<string>();
  const [group, setSelectedGroup] = React.useState<API.ReportingGroup>();
  const [theme, setSelectedTheme] = React.useState<API.Theme>();

  const startDate = format(sub(new Date(), { weeks: 15 }), "yyyy-MM-dd");
  const endDate = format(new Date(), "yyyy-MM-dd");

  const groupId = group?.id;
  const themeId = theme?.id;

  // If group id is defined, fetch the data from reporting group history query
  const { data: reportingGroupHistory } = useQuery<API.ReportingGroupHistoryQuery, API.ReportingGroupHistoryVars>(
    REPORTING_GROUP_HISTORY_QUERY,
    {
      variables: {
        reportingGroupId: groupId,
        theme: themeId,
        startDate: startDate,
        endDate: endDate,
      },
      // Skip executing query when group is not selected
      skip: !groupId,
    }
  );

  const {
    data: organizationHistory,
    error: organizationError,
    loading: organizationLoading,
  } = useQuery<API.OrganizationHistoryQuery, API.OrganizationHistoryVars>(ORGANIZATION_HISTORY_QUERY, {
    variables: {
      theme: themeId,
      startDate,
      endDate,
    },
    // Skip executing query when group is selected
    skip: !!groupId || !themeId,
  });

  const { data: reportingGroups } = useQuery<ReportingGroupsData>(REPORTING_GROUP_QUERY);

  // TODO: Do we need this reset? If so we should reset it in the graph as well
  // Reset the week selection after changing the group.
  // React.useEffect(() => {
  //   setSelectedWeekDelta(undefined);
  // }, [groupId]);

  const historyData = groupId ? reportingGroupHistory?.reportingGroupHistory : organizationHistory?.organizationHistory;

  // TODO: fetch this separately?
  const emotionalStates = groupId ? reportingGroupHistory?.emotionalStates : organizationHistory?.emotionalStates;

  // TODO: fetch this separately?
  const drivers = groupId ? reportingGroupHistory?.drivers : organizationHistory?.drivers;

  // TODO: fetch this separately?
  const emotionalStateCategories = groupId
    ? reportingGroupHistory?.emotionalStateCategories
    : organizationHistory?.emotionalStateCategories;

  const statistics = getStatisticsFromHistoryData(historyData, selectedWeekNumber);
  const driverCategories = getDriversFromHistoryData({
    drivers,
    emotionalStateCategories,
    historyData,
    weekNumber: selectedWeekNumber,
  });

  return (
    <Page>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <H1>
          {t("overview title")} –{" "}
          {
            group?.id === null
              ? t("whole organisation") // If no group selected, show the whole organisation
              : getLocaleValue(group?.name, i18n.language) // If group is selected, show the group name and translate it
          }
        </H1>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ margin: 12 }}>
            <ThemeSelect setValue={setSelectedTheme} />
          </div>
          <div style={{ margin: 12 }}>
            <GroupSelect data={reportingGroups} setValue={setSelectedGroup} />
          </div>
        </div>
      </div>
      <Graph
        data={historyData ?? []}
        emotionalStates={emotionalStates}
        // statistics={statistics}
        onClickWeek={setSelectedWeekNumber}
        selectedWeekNumber={selectedWeekNumber}
        loading={organizationLoading}
        error={organizationError}
      />
      <WeekData>
        <Drivers driverCategories={driverCategories} statistics={statistics} />
        {/* <Statistics stats={statistics} /> */}
        {selectedWeekNumber &&
          historyData &&
          !!historyData.find(({ week }) => week.fromDate === selectedWeekNumber)?.stuckage?.length && (
            <Stuckages data={historyData.find(({ week }) => week.fromDate === selectedWeekNumber)?.stuckage} />
          )}
      </WeekData>
    </Page>
  );
};

export default Overview;

const WeekData = styled.div`
  /* display: flex;
  flex-direction: column; */
`;
