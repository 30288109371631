import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components";

type Props = {
  onClick: MouseEventHandler<HTMLElement>;
};

const LinkButton: FC<Props> = ({ onClick, children }) => {
  return <SButton onClick={onClick}>{children}</SButton>;
};

export default LinkButton;

const SButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: #3a3a3a;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
