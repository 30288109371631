/**
 * Gets the locale value from provided json structure.
 * @param value JSON string containing following structure { "fi": "tekstiä", "en": "some text"}
 * @param locale Locale to be fetched from the json, fallbacks to en if locale not found from the JSON
 */
export const getLocaleValue = (value: string | undefined, locale: string = "en"): string => {
  if (!value) return "";
  const translations: Record<string, string> = JSON.parse(value);
  return translations[locale] ?? translations["en"];
};
