import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import Card from "../../components/Card";
import Chip from "../../components/Chip";
import Section from "../../components/Section";
import Modal, { useModal } from "../../components/Modal";

import { API } from "../../types";

type Props = {
  data?: API.Stuckage[];
};

const Stuckages: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { isOpen, toggle } = useModal();

  return (
    <Section title={t("stuckageSectionTitle")} subtitle={t("stuckageSectionSubtitle")}>
      <ButtonContainer>
        <Button onClick={toggle}>{t("readMore")}</Button>
        <Modal isOpen={isOpen} toggle={toggle}>
          <h2>{t("stuckageModalTitle")}</h2>
          <Trans i18nKey="stuckageModalContent">
            <p>
              There are assessment results for one or more individuals in your team, which tell you that the person or
              persons are “freezing” in a particular zone of well-being and performance.
            </p>
            <p>
              <NavLink to="/learn" target="_blank">
                Read more
              </NavLink>{" "}
              from the supervisor’s learning environment on how to lead people, who find themselves repeatedly in the
              same zone of well-being and performance.
            </p>
          </Trans>
        </Modal>
      </ButtonContainer>
      <CardsContainer>
        {data &&
          [...data]
            ?.sort((a, b) => (a.category.ordinal ?? 0) - (b.category.ordinal ?? 0))
            .map((stuckage, index) => <StuckageCard key={index} stuckage={stuckage} />)}
      </CardsContainer>
    </Section>
  );
};

export default Stuckages;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: -1rem;
`;

const Button = styled.button`
  background-color: #3a3a3a;
  border: none;
  border-radius: 6px;
  padding: 12px;
  color: white;
  cursor: pointer;

  :hover {
    opacity: 0.95;
  }
`;

const CardsContainer = styled.div`
  --space-between-items: 1.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: 0 calc(-1 * (var(--space-between-items) / 2)); /* Negative margin to adjust the sides correctly since the flex items have margins on their left and right */
`;

type StuckageCardProps = {
  stuckage: API.Stuckage;
};

const StuckageCard = (props: StuckageCardProps) => {
  const { category, drivers } = props.stuckage;
  const { t, i18n } = useTranslation();

  // Create a unique set of emotional state names because there can be multiple states, mainly because of their secondary categories
  const uniqueEmotionalStateNames = [
    ...new Set(category.emotionalStates?.map((e) => JSON.parse(e.name)[i18n.language])),
  ];

  return (
    <SCard backgroundColor={category.color}>
      <h2>{category.name[i18n.language]}</h2>
      <h3>({uniqueEmotionalStateNames.join(", ")})</h3>

      {drivers.length <= 0 && <p>{t("noDrivers")}</p>}

      {drivers.filter((driver) => driver.isSupporting).length > 0 && (
        <>
          <h4>{t("supportingDrivers")}</h4>
          <DriversContainer>
            {drivers
              .filter((driver) => driver.isSupporting)
              .map((driver, index) => (
                <SChip key={index} onColoredBackground>
                  {driver.isSupporting ? "👍" : "👎"}{" "}
                  {driver.description ?? JSON.parse(driver.driver.name)[i18n.language]}
                </SChip>
              ))}
          </DriversContainer>
        </>
      )}
      {drivers.filter((driver) => !driver.isSupporting).length > 0 && (
        <>
          <h4>{t("nonSupportingDrivers")}</h4>
          <DriversContainer>
            {drivers
              .filter((driver) => !driver.isSupporting)
              .map((driver, index) => (
                <SChip key={index} onColoredBackground>
                  {driver.isSupporting ? "👍" : "👎"}{" "}
                  {driver.description ?? JSON.parse(driver.driver.name)[i18n.language]}
                </SChip>
              ))}
          </DriversContainer>
        </>
      )}
    </SCard>
  );
};

const SCard = styled(Card)`
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0 calc(var(--space-between-items) / 2) var(--space-between-items);
  justify-content: space-between;

  max-width: 24rem;
  width: calc(100% - var(--space-between-items));

  @media (min-width: 991px) {
    width: calc(50% - var(--space-between-items));
  }
  @media (min-width: 1499px) {
    width: calc(33.333% - var(--space-between-items));
  }
`;

const DriversContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
`;

const SChip = styled(Chip)`
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;
