import React from "react";
import { gql, useMutation } from "@apollo/client";
import Select from "react-select";

import Modal, { ModalProps } from "../../components/Modal";
import { H1 } from "../../components/Text";
import Button from "../../components/Button";
import { formatNameWithEmail } from "../../utils/format";

import { ManageQuery, OrganizationUser } from "../../gql/graphql";
import { useTranslation } from "react-i18next";
import { getLocaleValue } from "../../utils/localization";

type AddMembersProps = {
  title: string;
  reportingGroup: ManageQuery["reportingGroups"][number];
  usersNotInGroup?: OrganizationUser[];
} & ModalProps;

type OptionType = {
  value: string;
  label: string;
};

const INSERT_REPORTING_GROUPS_MEMBERS_MUTATION = gql`
  mutation insertReportingGroupsMembers($users: [reportingGroups_members_insert_input!]!) {
    insert_reportingGroups_members(objects: $users) {
      affected_rows
      returning {
        reportingGroupId
        userId
        startDate
        endDate
      }
    }
  }
`;

const AddMembers = ({ title, reportingGroup, usersNotInGroup, isOpen, toggle }: AddMembersProps) => {
  const options = usersNotInGroup
    ?.map((user) => {
      return { value: user.Username, label: formatNameWithEmail(user) };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const { t, i18n } = useTranslation();

  const [selected, setSelected] = React.useState<OptionType[]>([]);

  const [insertMembers, { loading, error }] = useMutation(INSERT_REPORTING_GROUPS_MEMBERS_MUTATION);

  const handleSubmit = async () => {
    if (selected.length > 0) {
      try {
        const users = selected?.map((user) => {
          return { reportingGroupId: reportingGroup.id, userId: user.value, startDate: new Date() };
        });

        await insertMembers({
          variables: { users },
          update(cache, { data }) {
            const { returning } = data.insert_reportingGroups_members;
            console.log("cache return value", returning);
            cache.modify({
              id: cache.identify({ __typename: "reportingGroups", id: reportingGroup.id }),
              fields: {
                reportingGroups_members(existingMembers = []) {
                  return [...existingMembers, ...returning];
                },
              },
            });
          },
        });

        toggle();
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleChange = (value: unknown) => {
    setSelected(value as OptionType[]);
  };

  const handleToggle = () => {
    if (selected.length > 0) {
      const confirmClose = window.confirm(t("manageGroups.addMembers.confirmOnClose"));
      if (confirmClose) {
        setSelected([]);
        toggle();
      }
    } else {
      toggle();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} closeOnBackgroundClick={false}>
      <div style={{ width: "600px" }}>
        <H1 style={{ lineHeight: "24px", marginTop: 0 }}>
          {t("manageGroups.addMembers.title", { name: getLocaleValue(reportingGroup.name, i18n.language) })}
        </H1>
        <p>{t("manageGroups.addMembers.instructions")}</p>
        <Select<any>
          placeholder={t("manageGroups.addMembers.placeholder")}
          options={options}
          onChange={handleChange}
          closeMenuOnSelect={false}
          isMulti
          isClearable={false}
          backspaceRemovesValue={false}
          noOptionsMessage={() => t("manageGroups.addMembers.noOptions")}
        />
        <p>{t("manageGroups.addMembers.membershipStarts")}</p>
        <Button isDisabled={selected.length === 0} isLoading={loading} onClick={handleSubmit}>
          {t("manageGroups.addMembers.submit")}
        </Button>
        {error && <p>{t("manageGroups.addMembers.error")}</p>}
      </div>
    </Modal>
  );
};

export default AddMembers;
