import { Auth } from "aws-amplify";
import React from "react";

type GraphQLResponse<T extends string, R> = {
  data: {
    [S in T]: R[];
  };
};

async function fetchGraphQL<T extends string = any, R = any>(
  text: String,
  variables?: Object
): Promise<GraphQLResponse<T, R>> {
  const session = await Auth.currentAuthenticatedUser();
  const token = session.signInUserSession.idToken.getJwtToken();

  //console.log(token)

  const response = await fetch("https://api.poweredbyemotion.io/v1/graphql", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "x-hasura-role": "org-admin",
    },
    body: JSON.stringify({
      query: text,
      variables,
    }),
  });

  // Get the response as JSON
  return await response.json();
}

export default fetchGraphQL;

export const useGraphQL = <T>(
  query?: string,
  params?: Record<string, any>
): any => {
  const [data, setData] = React.useState<T[][] | undefined>(undefined);
  React.useEffect(() => {
    let isMounted = true;
    if (!query) return;
    setData(undefined);
    fetchGraphQL(query, params)
      .then((response) => {
        // Avoid updating state if the component unmounted before the fetch completes
        if (!isMounted) {
          return;
        }

        setData((response as any).data);
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      isMounted = false;
    };
  }, [query, params]);
  return data;
};
