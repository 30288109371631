/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-central-1",
  aws_cognito_identity_pool_id: "eu-central-1:98000cc9-26f9-44a7-91cf-1110e932c204",
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: "eu-central-1_rTGJ5EJeR",
  aws_user_pools_web_client_id: "660qka31ujlvssf7qj4k5eanee",
  oauth: {}
};

export default awsmobile;
