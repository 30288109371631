import * as React from "react";
import { useTranslation } from "react-i18next";
import Popover, { ArrowContainer } from "react-tiny-popover";
import styled from "styled-components";

import Chip from "../../../components/Chip";

import { ResultDriver } from "../../../types";

import { getLocaleValue } from "../../../utils/localization";

export type DriverRowProps = {
  index: number;
  driver: ResultDriver;
};

const DriverRow = ({ index, driver }: DriverRowProps) => {
  return (
    <SRow>
      <Chip background={driver.isSupporting ? "rgba(31, 135, 75, 0.1)" : "rgba(236, 98, 98, 0.1)"}>
        {driver.isSupporting ? "👍" : "👎"} <DriverName driver={driver} />
      </Chip>
    </SRow>
  );
};

type DriverNameProps = {
  driver: ResultDriver;
};
const DriverName = (props: DriverNameProps) => {
  const { driver } = props;
  const {
    i18n: { language },
  } = useTranslation();

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  // No idea about this one..
  if (driver.descriptions.length === 0) {
    return <span>{getLocaleValue(driver.driver.name, language)}</span>;
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      position={["right", "bottom", "top", "left"]}
      padding={16}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={"#000000CC"}
          arrowSize={6}
          arrowStyle={{}}
        >
          <div className="popover" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
            {driver.descriptions.length > 1 ? (
              <ul>
                {driver.descriptions.map((description, key) => (
                  <li key={key}>{description}</li>
                ))}
              </ul>
            ) : (
              <div>{driver.descriptions[0]}</div>
            )}
          </div>
        </ArrowContainer>
      )}
    >
      {/* TODO: update these to be buttons instead of links as links are not buttons... */}

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
      <a onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        {getLocaleValue(driver.driver.name, language)}&nbsp;({driver.descriptions.length})
      </a>
    </Popover>
  );
};

export default DriverRow;

const SRow = styled.li`
  margin-bottom: 0.5rem;
`;
