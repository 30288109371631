import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import BarStack from "./Graphs/BarStack";

import { ReactComponent as LoadingSpinner } from "../../assets/loading.svg";
import { API } from "../../types";

import { ApolloError } from "@apollo/client";
import { ReactComponent as WarningIcon } from "../../assets/warning.svg";

export type GraphProps = {
  onClickWeek: (weekDelta: string) => void;
  data: API.HistoryData;
  emotionalStates?: API.EmotionalState[];
  selectedWeekNumber?: string;
  loading: boolean;
  error: ApolloError | undefined;
};

const Graph = ({ onClickWeek, data = [], emotionalStates = [], selectedWeekNumber, loading, error }: GraphProps) => {
  const { t } = useTranslation();

  return (
    <GraphContainer>
      {loading || error ? (
        <SpinnerContainer>
          {loading ? (
            <>
              <LoadingSpinner />
              <h2>{t("loadingResults")}</h2>
            </>
          ) : (
            <>
              <WarningIcon />
              <h2>{t("errorTitle")}</h2>
            </>
          )}
        </SpinnerContainer>
      ) : (
        <BarStack
          data={data}
          emotionalStates={emotionalStates}
          selectedWeekNumber={selectedWeekNumber}
          onClickWeek={onClickWeek}
        />
      )}
    </GraphContainer>
  );
};

export default Graph;

const GraphContainer = styled.div`
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: 75rem;
  width: 100%;
`;

const SpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
`;
