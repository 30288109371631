import React from "react";
import { gql, useMutation } from "@apollo/client";

import LinkButton from "../../components/LinkButton";
import { useTranslation } from "react-i18next";
import { ManageQuery, OrganizationUser, ReportingGroups_Members } from "../../gql/graphql";
import Modal, { useModal } from "../../components/Modal";
import { getLocaleValue } from "../../utils/localization";
import { H1 } from "../../components/Text";
import Button from "../../components/Button";
import { HorizontalStack } from "../../components/Stack";
import { Member } from "./MembersTable";

type MemberActionsProps = {
  user: OrganizationUser;
  members: Member[];
  reportingGroup: ManageQuery["reportingGroups"][number];
};

const END_MEMBERSHIP_MUTATION = gql`
  mutation updateReportingGroupsMembers($userId: String!, $reportingGroupId: Int!, $endDate: date) {
    update_reportingGroups_members_by_pk(
      pk_columns: { reportingGroupId: $reportingGroupId, userId: $userId }
      _set: { endDate: $endDate }
    ) {
      userId
      reportingGroupId
      startDate
      endDate
    }
  }
`;

const MemberActions = ({ user, members, reportingGroup }: MemberActionsProps) => {
  const [endMembership, { loading }] = useMutation(END_MEMBERSHIP_MUTATION);
  const { t, i18n } = useTranslation();

  const { isOpen, toggle } = useModal();

  const activeMemberCount = members.filter((member) => !member.endDate).length;
  const lteFiveMembers = activeMemberCount <= 5;

  const displayName = user.given_name && user.family_name ? `${user.family_name}, ${user.given_name}` : user.email;

  const handleEndMembership = async () => {
    try {
      const resp = await endMembership({
        variables: {
          userId: user.Username,
          reportingGroupId: reportingGroup.id,
          endDate: new Date(),
        },
        update(cache, { data }) {
          const { update_reportingGroups_members_by_pk } = data;
          cache.modify({
            id: cache.identify({ __typename: "reportingGroups", id: reportingGroup.id }),
            fields: {
              reportingGroups_members(existingMembers = []) {
                // Filter out the updated user and replace it with updated data returned by the mutation
                const filterUpdated = existingMembers.filter(
                  (member: ReportingGroups_Members) => member.userId !== user.Username
                );
                return [...filterUpdated, update_reportingGroups_members_by_pk];
              },
            },
          });
        },
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <LinkButton onClick={toggle}>{t("manageGroups.memberActions.end")}</LinkButton>
      <Modal align="flex-start" isOpen={isOpen} toggle={toggle} closeOnBackgroundClick={false}>
        <H1 style={{ lineHeight: "24px", marginTop: 0 }}>
          {t("manageGroups.memberActions.endModal.title", {
            name: getLocaleValue(reportingGroup.name, i18n.language),
            user: displayName,
          })}
        </H1>
        <p style={{ marginBottom: "1rem" }}>
          {t("manageGroups.memberActions.endModal.message", { user: displayName })}
        </p>
        <p style={{ marginBottom: "1rem" }}>
          {t("manageGroups.memberActions.endModal.additionalInstructions", { user: displayName })}
        </p>
        {lteFiveMembers && (
          <p style={{ color: "red" }}>{t("manageGroups.memberActions.endModal.lessThanFiveMembers")}</p>
        )}
        <HorizontalStack>
          <Button onClick={handleEndMembership} isLoading={loading} isDisabled={lteFiveMembers}>
            {t("manageGroups.memberActions.endModal.confirm")}
          </Button>
          <Button onClick={toggle} isDisabled={loading}>
            {t("manageGroups.memberActions.endModal.cancel")}
          </Button>
        </HorizontalStack>
      </Modal>
    </>
  );
};

export default MemberActions;
