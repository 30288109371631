import { gql } from "@apollo/client";
import "./overview.css";

export const getDriverHistory = (weekDelta: any | undefined, groupId: any, themeId: any) => {
  const driversQuery = `
  query driversQuery($weekDelta: Int!, $groups: [String!], $theme: Int) {
    orgDriversDirection(weekDelta: $weekDelta, groups: $groups, theme: $theme) {
      driverIds
      driverPercents
      isSupporting
      descriptions
      weekNumber
      categoryId
      category {
        color
        name
        ordinal
      }
    }
    drivers {
      name
      id
    }

  }
`;
  return {
    query: driversQuery,
    params: {
      weekDelta,
      groups: groupId ? [groupId] : undefined,
      theme: themeId,
    },
  };
};

export const getOrgHistory = (weekCount: number[], groupId: string, themeId: string) => {
  const orgHistoryQuery = `query orgHistoryQuery {
    ${weekCount.map((weekDelta) => {
      return `
      week${weekDelta}: orgHistory(weekDelta: ${weekDelta}, ${groupId ? `groups:["${groupId}"]` : ""}
      ${themeId ? `, theme: ${themeId}` : ""}

      ) {
        weekNumber
        weight
        emotionalStateOrdinal
      }



      `;
    })}
    emotionalStates(distinct_on: ordinal) {
      color
      name
      ordinal
    }
  }`;

  return {
    query: orgHistoryQuery,
    params: undefined,
  };
};

export const getOrgStatistics = (weekDelta: any | undefined, groupId: any, themeId: any) => {
  const statisticsQuery = `
  query statisticsQuery($weekDelta: Int!, $groups: [String!], $theme: Int) {
    orgStatistics(weekDelta: $weekDelta, groups: $groups, theme: $theme) {
        weekNumber
        resultCount
        answeredUserCount
        usersCount
        answerPercent
    }
  }
`;

  return {
    query: statisticsQuery,
    params: {
      weekDelta,
      groups: groupId ? [groupId] : undefined,
      theme: themeId,
    },
  };
};

export const REPORTING_GROUP_HISTORY_QUERY = gql`
  query ReportingGroupHistoryQuery($endDate: date!, $reportingGroupId: Int!, $startDate: date!, $theme: Int!) {
    reportingGroupHistory(
      input: { reportingGroupId: $reportingGroupId, theme: $theme, endDate: $endDate, startDate: $startDate }
    ) {
      drivers
      results
      statistics
      stuckage
      week
    }
    emotionalStates(distinct_on: ordinal) {
      color
      name
      ordinal
      icon
    }
    drivers {
      name
      id
    }
    emotionalStateCategories {
      id
      color
      name
      ordinal
    }
  }
`;

export const ORGANIZATION_HISTORY_QUERY = gql`
  query OrganizationistoryQuery($endDate: date!, $startDate: date!, $theme: Int!) {
    organizationHistory(input: { theme: $theme, endDate: $endDate, startDate: $startDate }) {
      drivers
      results
      statistics
      stuckage
      week
    }
    emotionalStates(distinct_on: ordinal) {
      color
      name
      ordinal
      icon
    }
    drivers {
      name
      id
    }
    emotionalStateCategories {
      id
      color
      name
      ordinal
    }
  }
`;

export const REPORTING_GROUP_QUERY = gql`
  query GroupQuery {
    reportingGroups {
      id
      name
    }
  }
`;
