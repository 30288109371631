import * as React from "react";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import Modal, { useModal } from "../../../../components/Modal";

import Legend from "./Legend";
import BarStack from "./BarStack";

import { ReactComponent as InfoIcon } from "../../../../assets/info.svg";

import { API } from "../../../../types";

type BarStackProps = {
  data: API.HistoryData;
  emotionalStates: API.EmotionalState[];
  selectedWeekNumber?: string;
  onClickWeek: (weekDelta: string) => void;
};

const BarStackContainer = ({ data, emotionalStates, selectedWeekNumber, onClickWeek }: BarStackProps) => {
  const { t } = useTranslation();
  const { isOpen, toggle } = useModal();

  return (
    <div>
      <TopContainer>
        <GraphContainer>
          <BarStack
            data={data}
            emotionalStates={emotionalStates}
            selectedWeekNumber={selectedWeekNumber}
            onClickWeek={onClickWeek}
          />
        </GraphContainer>
        <RightContainer>
          <Legend emotionalStates={emotionalStates} />
        </RightContainer>
      </TopContainer>
      <BottomContainer>
        <BottomContent>
          <InfoIcon height={14} width={14} style={{ verticalAlign: "top" }} /> {t("repetitionNotification")}{" "}
          {t("repetitionLegend")} <a onClick={toggle}>{t("repetitionReadMore")}</a>
        </BottomContent>
        <Modal isOpen={isOpen} toggle={toggle}>
          <h2>{t("stuckageModalTitle")}</h2>
          <Trans i18nKey="stuckageModalContent">
            <p>
              There are assessment results for one or more individuals in your team, which tell you that the person or
              persons are “freezing” in a particular zone of well-being and performance.
            </p>
            <p>
              <NavLink to="/learn" target="_blank">
                Read more
              </NavLink>{" "}
              from the supervisor's learning environment on how to lead people, who find themselves repeatedly in the
              same zone of well-being and performance.
            </p>
          </Trans>
        </Modal>
      </BottomContainer>
    </div>
  );
};

export default BarStackContainer;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const GraphContainer = styled.div`
  flex-grow: 1;
`;

const BottomContainer = styled.div`
  border-top: 1px solid #ebebef;
  padding: 1rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
`;

const BottomContent = styled.p`
  max-width: 36rem;
  margin: auto;
  font-size: 0.8rem;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid #ebebef;
  padding: 1.5rem;
`;
