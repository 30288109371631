import React, { FC } from "react";
import styled from "styled-components";

interface CardProps {
  title?: string;
  backgroundColor?: string;
  centered?: boolean;
}

const Card: FC<CardProps> = ({ title, backgroundColor, children, ...rest }) => {
  return (
    <SCard backgroundColor={backgroundColor} {...rest}>
      {title && <STitle>{title}</STitle>}
      <SContainer>{children}</SContainer>
    </SCard>
  );
};

interface ExtendableCardProps extends CardProps {
  isOpen?: boolean;
  toggle?: () => void;
  actionTitle?: string;
}

export const ExpandableCard: FC<ExtendableCardProps> = ({
  title,
  isOpen,
  toggle,
  backgroundColor,
  children,
  actionTitle,
  ...rest
}) => {
  return (
    <SExpandableCard isOpen={isOpen} backgroundColor={backgroundColor} {...rest}>
      {title && (
        <SExpandableHeader isOpen={isOpen} onClick={toggle}>
          <STitle>{title}</STitle>
          <SExpandAction>
            {actionTitle && <p>{actionTitle}</p>}
            <SChevron direction={isOpen ? "top" : "bottom"} />
          </SExpandAction>
        </SExpandableHeader>
      )}
      {isOpen && <SContainer>{children}</SContainer>}
    </SExpandableCard>
  );
};

export default Card;

interface ISCard {
  backgroundColor?: string;
  centered?: boolean;
}

const SCard = styled.article<ISCard>`
  background: ${(props) => props.backgroundColor ?? "#ffffff"};
  color: ${(props) => (props.backgroundColor ? "#ffffff" : "inherit")};
  border-radius: 1rem;
  display: flex;
  padding: 2rem;
  margin-top: 24px;
  margin-bottom: 24px;
  flex-direction: column;
  align-items: ${({ centered = true }) => (centered ? "center" : "flex-start")};
`;

const STitle = styled.h3`
  margin-top: 0;
`;

const SContainer = styled.div`
  width: 100%;
`;

interface ISExpandableCard extends ISCard {
  isOpen?: boolean;
}

interface ISExpandableHeader {
  isOpen?: boolean;
}

const SExpandableCard = styled(SCard)<ISExpandableCard>`
  padding-top: 0;
  padding-bottom: ${({ isOpen }) => (isOpen ? "2rem" : "0")};
`;

const SExpandableHeader = styled.div<ISExpandableHeader>`
  padding-top: 2rem;
  padding-bottom: ${({ isOpen }) => (isOpen ? "0" : "2rem")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-bottom: ${({ isOpen }) => (isOpen ? "1rem" : 0)};

  & > h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const SExpandAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  & > p {
    margin-right: 0.5rem;
  }
`;

interface IChevron {
  direction: "top" | "right" | "bottom" | "left";
}

const SChevron = styled.div<IChevron>`
  border-style: solid;
  border-width: 0.125rem 0.125rem 0 0;
  height: 0.5rem;
  width: 0.5rem;
  transition: all 0.25s ease-in-out;

  transform: ${(p) => p.direction === "top" && "rotate(-45deg)"};
  transform: ${(p) => p.direction === "right" && "rotate(45deg)"};
  transform: ${(p) => p.direction === "bottom" && "rotate(135deg)"};
  transform: ${(p) => p.direction === "left" && "rotate(-135deg)"};
`;
