const colorPrimary = '#3751FF';
const colorPrimaryVariant = '#2f95dc';
const colorAccent = '#D63864';
const colorSecondary = '#219653';

export default {
  light: {
    text: '#000',
    textSecondary: '#9D9CAF',
    background: '#fff',
    screenBackground: '#F5F8FF',
    tint: colorPrimary,
    tabIconDefault: '#757575',
    tabIconSelected: colorAccent,
    primary: colorPrimary,
    primaryVariant: colorPrimaryVariant,
    colorOnPrimary: '#fff',
    secondary: colorSecondary,
    colorOnSecondary: '#fff',
    colorOnSurface: '#353D4F',
    accent: colorAccent
  },
  dark: {
    text: '#000',
    background: '#fff',
    tint: colorAccent,
    tabIconDefault: '#ccc',
    tabIconSelected: colorAccent,
    primary: colorPrimary,
    secondary: colorPrimary,
    surfaceBackground: '#fff'
  },
};
