import styled from "styled-components";

export const HorizontalStack = styled.div`
  display: flex;
  flex-direction: row;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const VerticalStack = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;
