import React, { FC, useState } from "react";
import styled from "styled-components";
import StyledReactModal from "styled-react-modal";

export type ModalProps = {
  isOpen: boolean;
  toggle: () => void;
  align?: "flex-start" | "center" | "flex-end";
  closeOnBackgroundClick?: boolean;
};

const Modal: FC<ModalProps> = ({ align, children, isOpen, toggle, closeOnBackgroundClick = true }) => {
  return (
    <StyledModal
      // @ts-ignore
      align={align}
      isOpen={isOpen}
      onBackgroundClick={closeOnBackgroundClick ? toggle : undefined}
      onEscapeKeydown={toggle}
    >
      <CloseButton onClick={toggle} />
      {children}
    </StyledModal>
  );
};

export default Modal;

export const useModal = (initialValue = false) => {
  const [isOpen, setIsOpen] = useState(initialValue);

  const toggle = () => setIsOpen(!isOpen);

  return { isOpen, toggle };
};

const StyledModal = StyledReactModal.styled`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${({ align = "center" }) => align};
  justify-content: center;
  background-color: white;
  padding: 3rem;
  border-radius: 1rem;
  max-width: 54rem;
  margin: 2rem;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  width: 1rem;
  height: 1rem;
  opacity: 0.3;

  padding: 0;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    width: 1px;
    height: 100%;
    background: #333;
    display: block;
    transform: rotate(45deg) translateX(0px);
    position: absolute;
    left: 50%;
    top: 0;
  }

  &::after {
    transform: rotate(-45deg) translateX(0px);
  }
`;
