import React, { FC } from "react";
import styled from "styled-components";

type ChipProps = {
  onColoredBackground?: boolean;
  background?: string;
};

const Chip: FC<ChipProps> = ({ onColoredBackground, background, children, ...rest }) => {
  return (
    <SChip onColoredBackground={onColoredBackground} background={background} {...rest}>
      {children}
    </SChip>
  );
};

export default Chip;

interface IChip {
  onColoredBackground?: boolean;
  background?: string;
}

const SChip = styled.div<IChip>`
  display: inline-block;
  padding: 0.35rem 0.75rem;
  background: ${(props) =>
    props.background
      ? props.background
      : props.onColoredBackground
      ? "rgba(255, 255, 255, 0.08)"
      : "rgba(0, 0, 0, 0.05)"};
  border-radius: 6.25rem;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.5px;
  font-weight: bold;
`;
