import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { getIconByOrdinal } from "./utils";

import { API } from "../../../../types";

type LegendProps = {
  emotionalStates: API.EmotionalState[];
};

const Legend = ({ emotionalStates }: LegendProps) => {
  return (
    <EmotionalStateList>
      {[...emotionalStates]
        .sort((a, b) => (a.ordinal < b.ordinal ? 1 : -1))
        .map((emotionalState, index) => (
          <EmotionalStateListItem key={index} emotionalState={emotionalState} />
        ))}
    </EmotionalStateList>
  );
};
export default Legend;

const EmotionalStateList = styled.ul`
  list-style: none;
  padding: 0;
`;

const EmotionalStateListItem = ({ emotionalState }: { emotionalState: API.EmotionalState }) => {
  const { i18n } = useTranslation();

  const Icon = getIconByOrdinal(emotionalState.ordinal);

  return (
    <ListItem key={emotionalState.ordinal} color={emotionalState.color}>
      {Icon && <Icon height={20} width={20} style={{ marginRight: "0.5em" }} />}
      <span>{JSON.parse(emotionalState.name)[i18n.language]}</span>
    </ListItem>
  );
};

const ListItem = styled.li`
  --color: ${(props) => props.color};
  display: flex;
  align-items: center;
  color: var(--color);
  font-size: 0.8rem;
  margin-bottom: 2.5em;
  &:last-child {
    margin-bottom: 0;
  }
`;
