import { OrganizationUser } from "../gql/graphql";

export const formatName = (user: OrganizationUser | undefined) => {
  return user?.given_name && user?.family_name ? `${user.family_name}, ${user.given_name}` : "";
};

export const formatNameWithEmail = (user: OrganizationUser | undefined) => {
  if (!user) return "";

  const name = formatName(user);
  return name.length > 0 ? `${name} <${user?.email}>` : user?.email;
};
