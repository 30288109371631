import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Amplify, Auth } from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from "@aws-amplify/ui-react";
import { onAuthUIStateChange, AuthState } from "@aws-amplify/ui-components";
import { I18nextProvider, useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { ThemeProvider } from "styled-components";
import { ModalProvider } from "styled-react-modal";

import App from "./App";
import LanguageChanger from "./components/LanguageChanger";

import * as serviceWorker from "./serviceWorker";
import i18n from "./utils/i18n";
import config from "./aws-exports";

import logo from "./assets/sparky_logo_tumma.png";

import "./index.css";

Amplify.configure(config);

ReactGA.initialize("UA-179409678-1");

const httpLink = createHttpLink({
  uri: "https://api.poweredbyemotion.io/v1/graphql",
});

const authLink = setContext(async (_, { headers }) => {
  const session = await Auth.currentAuthenticatedUser();
  const token = session.signInUserSession.idToken.getJwtToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "x-hasura-role": "org-admin",
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const AuthenticatedApp = () => {
  const [role, setRole] = useState([]);
  const [authState, setAuthState] = useState();
  const adminRole = "org-admin";

  const { t, i18n } = useTranslation();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      const roles = authData?.signInUserSession?.accessToken?.payload["cognito:groups"];
      setRole(roles || []);
      setAuthState(nextAuthState);

      const userId = authData?.signInUserSession?.idToken?.payload["cognito:username"];
      const userOrganization = roles?.filter((role: string) => role !== adminRole)[0]?.split("-")[0];

      if (nextAuthState === AuthState.SignedOut) {
        // Clear apollo cache if user logs out
        apolloClient.clearStore();
      }

      if (userId && userOrganization) {
        ReactGA.set({
          userId,
          userOrganization,
        });
      }
    });
  }, []);

  return authState === AuthState.SignedIn && role.includes(adminRole) ? (
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  ) : (
    <div style={{ position: "relative" }}>
      <div
        style={{
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "2vh",
          paddingBottom: "2vh",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }}
      >
        <img src={logo} alt="Sparky" style={{ width: "16rem", maxWidth: "75%" }} />
      </div>
      {authState !== AuthState.SignedIn ? (
        <AmplifyAuthenticator>
          {/* The key prop is needed in order to force the component to re-render after language change */}
          <AmplifySignIn key={i18n.language} slot="sign-in" hideSignUp />
        </AmplifyAuthenticator>
      ) : (
        <div style={{ paddingBottom: "10vh", paddingTop: "10vh" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <p>{role.includes(adminRole) ? t("genericAuthenticationIssue") : t("roleAuthenticationIssue")}</p>
          </div>
          <div style={{ textAlign: "center" }}>
            <AmplifySignOut style={{ display: "inline-block" }} key={i18n.language} />
          </div>
        </div>
      )}
      <aside style={{ position: "fixed", left: 0, bottom: 0, width: "100%" }}>
        <LanguageChanger />
      </aside>
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={{}}>
        <ModalProvider>
          <AuthenticatedApp />
        </ModalProvider>
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
