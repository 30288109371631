import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components";

type ButtonProps = {
  onClick: MouseEventHandler<HTMLElement>;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const Button: FC<ButtonProps> = ({ onClick, children, isLoading, isDisabled }) => {
  return (
    <SButton disabled={isDisabled || isLoading} loading={isLoading} onClick={onClick}>
      {children}
    </SButton>
  );
};

type StyledProps = {
  loading?: boolean;
};

export default Button;

const SButton = styled.button<StyledProps>`
  background: #3a3a3a;
  color: inherit;
  border: 2px solid #3a3a3a;
  border-radius: 0.5rem;
  font: inherit;
  outline: inherit;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  color: #fff;
  cursor: ${(props) => (props.loading ? "wait" : props.disabled ? "not-allowed" : "pointer")};
  padding: 0.5rem 1rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Amazon Ember", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.75px;
  font-size: 14px;
  margin: 0.5rem 0;
`;
