import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import "./App.css";

import Overview from "./screens/Overview/Overview";
import Emotions from "./screens/Emotions/Emotions";
import Manage from "./screens/Manage";

import Sidebar from "./components/Sidebar";

import GoogleAnalytics from "./utils/GoogleAnalytics";

type PageProps = {
  children: React.ReactNode;
};

export const Page = ({ children }: PageProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      {children}
      <Footer>
        <a href="https://www.sparky.fi/_files/ugd/11cda3_398f76279caa4fed963e6513ff670038.pdf" target="_blank">
          {t("privacyPolicy")}
        </a>
      </Footer>
    </PageContainer>
  );
};

const routes = [
  {
    path: "/",
    exact: true,
    main: () => <Overview />,
  },
  {
    path: "/learn",
    exact: true,
    main: () => <Emotions />,
  },
  {
    path: "/manage",
    exact: true,
    main: () => <Manage />,
  },
];

function App() {
  return (
    <Router>
      <div style={{ display: "flex" }}>
        <Sidebar />

        <Main>
          <Switch>
            {routes.map((route, index) => (
              <Route key={route.path} path={route.path} exact={route.exact} children={<route.main />} />
            ))}
          </Switch>
        </Main>
      </div>
      <GoogleAnalytics />
    </Router>
  );
}

export default App;

const Main = styled.main`
  min-height: 100vh;
  flex: 1;
  margin-left: var(--sidebar-width);
`;

const PageContainer = styled.div`
  background-color: #f7f8fc;
  min-height: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
`;

const Footer = styled.footer`
  margin-top: auto;
  padding: 1rem;
  width: 100%;
  text-align: center;

  a {
    color: var(--amplify-primary-color);
  }
`;
