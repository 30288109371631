import * as React from "react";

import { ReactComponent as AhdistusIcon } from "../assets/emotions/ahdistus.svg";
import { ReactComponent as MukavuusIcon } from "../assets/emotions/mukavuus.svg";
import { ReactComponent as PaineIcon } from "../assets/emotions/paine.svg";
import { ReactComponent as PakoIcon } from "../assets/emotions/pako.svg";
import { ReactComponent as StressiIcon } from "../assets/emotions/stressi.svg";
import { ReactComponent as TehoIcon } from "../assets/emotions/teho.svg";
import { ReactComponent as TurtaIcon } from "../assets/emotions/turta.svg";
import { ReactComponent as TyonIloIcon } from "../assets/emotions/tyon-ilo.svg";

function mapIcon(icon?: any, fill?: string, style) {
  if (fill === undefined) {
    fill = "#fff";
  }

  switch (icon) {
    case "coffee": {
      return <MukavuusIcon fill={fill} style={style} />;
    }
    case "battery": {
      return <TyonIloIcon fill={fill} style={style} />;
    }
    case "flame": {
      return <TehoIcon fill={fill} style={style} />;
    }
    case "waterdrops": {
      return <PaineIcon fill={fill} style={style} />;
    }
    case "lightning": {
      return <StressiIcon fill={fill} style={style} />;
    }
    case "clock": {
      return <AhdistusIcon fill={fill} style={style} />;
    }
    case "person": {
      return <PakoIcon fill={fill} style={style} />;
    }
    case "skull": {
      return <TurtaIcon fill={fill} style={style} />;
    }
    default: {
      return null;
    }
  }
}

export function EmotionIcon(props: EmotionIconProps) {
  const { icon, fill, style } = props;
  return mapIcon(icon, fill, style);
}

export type EmotionIconProps = {
  icon: any | undefined;
  style: any;
} & IconProps;

export type IconProps = {
  fill: string;
};
