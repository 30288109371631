import React from "react";
import { useTranslation } from "react-i18next";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import styled from "styled-components";

import LinkList from "./LinkList";
import LanguageChanger from "../LanguageChanger";

import logo from "../../assets/sparky_logo_tumma.png";

const Sidebar = () => {
  const { i18n } = useTranslation();

  return (
    <Aside>
      <LogoContainer>
        <Logo src={logo} alt="Sparky logo" />
      </LogoContainer>
      <LinkList />
      <LanguageChanger />
      <SignOutContainer>
        <AmplifySignOut key={i18n.language} />
      </SignOutContainer>
    </Aside>
  );
};

export default Sidebar;

const Aside = styled.aside`
  width: var(--sidebar-width);
  background-color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
`;

const LogoContainer = styled.div`
  padding: 2rem 1rem;
`;

const Logo = styled.img`
  width: 100%;
`;

const SignOutContainer = styled.div``;
