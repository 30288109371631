import styled from "styled-components";

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

type RowProps = {
  muted?: boolean;
};

export const TableHeader = styled.thead`
  & > tr {
    font-weight: 600;
  }
`;

export const TableBody = styled.tbody`
  & > tr:hover {
    background-color: #ddd;
  }
`;

export const Row = styled.tr<RowProps>`
  opacity: ${(props) => (props.muted ? "0.5" : "1")};
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const Cell = styled.td`
  padding: 0.5rem;
`;
