import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import DriverRow from "./DriversRow";
import Card from "../../../components/Card";
import LinkButton from "../../../components/LinkButton";
import Section from "../../../components/Section";

import { DriverCategory, OrgStatistics } from "../../../types";
import { getLocaleValue } from "../../../utils/localization";

type DriversProps = {
  driverCategories?: DriverCategory[];
  statistics?: OrgStatistics;
};

const Drivers = (props: DriversProps) => {
  const { driverCategories, statistics } = props;
  const { t } = useTranslation();

  const renderContent = () => {
    // If there are not enough answers to show the drivers and results, show a message
    if (statistics && statistics.answerPercent < statistics.requiredAnswerPercentage) {
      return (
        <Card>
          <MutedText>
            {t("Results are shown only if usage percent is over required coverage", {
              requiredAnswerPercentage: statistics.requiredAnswerPercentage * 100,
              answerPercent: statistics.answerPercent * 100,
            })}
          </MutedText>
        </Card>
      );
    }

    // If driverCategories is undefined ie. no week selected, then show prompt to select the week
    if (!driverCategories) {
      return (
        <Card>
          <MutedText>{t("select week to see drivers")}</MutedText>
        </Card>
      );
    }

    // Week selected but the selection does not contain data for the week ie. no one picked any drivers
    if (driverCategories.length === 0) {
      return (
        <Card>
          <MutedText>{t("no drivers")}</MutedText>
        </Card>
      );
    }

    return (
      <CardsContainer>
        {driverCategories.map((driverCategory) => (
          <DriverCard driverCategory={driverCategory} />
        ))}
      </CardsContainer>
    );
  };

  return <Section title={t("driversSectionTitle")}>{renderContent()}</Section>;
};

export default Drivers;

type DriverCardProps = {
  driverCategory: DriverCategory;
};

const DriverCard: FC<DriverCardProps> = ({ driverCategory }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const getDrivers = (category: DriverCategory) => {
    return showAll ? category.data : category.data.slice(0, 5);
  };

  return (
    <SCard key={driverCategory.category.color}>
      <SCategoryTitle color={driverCategory.category.color}>
        {getLocaleValue(driverCategory.category.name, language)}
      </SCategoryTitle>
      <SCategoryTable>
        {getDrivers(driverCategory).map((driver, index) => {
          return <DriverRow key={index} index={index} driver={driver} />;
        })}
      </SCategoryTable>
      {driverCategory.data.length > 5 && (
        <SButtonsContainer>
          {showAll ? (
            <LinkButton onClick={() => setShowAll(false)}>{t("Show top-5")}</LinkButton>
          ) : (
            <LinkButton onClick={() => setShowAll(true)}>{t("Show all")}</LinkButton>
          )}
        </SButtonsContainer>
      )}
    </SCard>
  );
};

const CardsContainer = styled.div`
  --space-between-items: 1.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: 0 calc(-1 * (var(--space-between-items) / 2)); /* Negative margin to adjust the sides correctly since the flex items have margins on their left and right */
`;

const SCard = styled(Card)`
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0 calc(var(--space-between-items) / 2) var(--space-between-items);
  justify-content: space-between;

  max-width: 24rem;
  width: calc(100% - var(--space-between-items));

  @media (min-width: 991px) {
    width: calc(50% - var(--space-between-items));
  }
  @media (min-width: 1199px) {
    width: calc(33.333% - var(--space-between-items));
  }
`;

const SCategoryTitle = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: ${(props) => props.color};
  margin-top: 0;
  margin-bottom: 30px;
`;

const SCategoryTable = styled.ol``;

const SButtonsContainer = styled.div`
  margin-top: 1.5rem;
`;

const MutedText = styled.p`
  opacity: 0.5;
`;
