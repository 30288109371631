import React, { FC } from "react";
import styled from "styled-components";

type Props = {
  title: string;
  subtitle?: string;
};

const Section: FC<Props> = ({ title, subtitle, children }) => {
  return (
    <SSection>
      <TitleContainer>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TitleContainer>

      <div>{children}</div>
    </SSection>
  );
};

export default Section;

const SSection = styled.section`
  margin: 4rem 0;
`;

const TitleContainer = styled.div`
  margin-bottom: 2rem;
`;

const Title = styled.h3`
  text-align: center;
  margin-top: 0;
`;

const Subtitle = styled.p`
  text-align: center;
  opacity: 0.8;
  max-width: 60ch;
  margin: auto;
`;
