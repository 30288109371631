import * as React from "react";
import {
  VictoryChart,
  VictoryStack,
  VictoryBar,
  VictoryLabel,
  VictoryAxis,
  VictoryScatter,
  VictoryTooltip,
} from "victory";
import { useTranslation } from "react-i18next";
import moment from "moment";
import styled from "styled-components";

import { getIconByOrdinal } from "./utils";

import { API } from "../../../../types";

import { ReactComponent as InfoIcon } from "../../../../assets/info.svg";

const colors = {
  axis: "#EBEBEF",
  label: {
    default: "#9D9CAF",
    selected: "#1D1D1D",
  },
};

type BarStackProps = {
  data: API.HistoryData;
  emotionalStates: API.EmotionalState[];
  selectedWeekNumber?: string;
  onClickWeek: (weekDelta: string) => void;
};

const BarStack = ({ data, emotionalStates, selectedWeekNumber, onClickWeek }: BarStackProps) => {
  const { t, i18n } = useTranslation();

  const getDataForEmotionalState = (data: API.HistoryData, emotionalState: API.EmotionalState): any[] => {
    const res = data.map((d) => {
      const { answerPercent, requiredAnswerPercentage } = d.statistics;

      // Return 0 if there are not enough answers
      if (answerPercent < requiredAnswerPercentage) return { x: d.week.fromDate, y: 0 };

      // Calculate total of all emotional state weights in this weeks results
      const totalWeight = d.results.reduce((acc, cur) => acc + cur.weight, 0);
      // Find the results for this emotional state
      const emotionalStateResult = d.results.find((r) => r.emotionalStateOrdinal === emotionalState.ordinal);
      // Get weight of this emotional state
      const weight = emotionalStateResult ? emotionalStateResult.weight : 0;
      // Calculate percentage of this emotional state, i.e. how many percents of the total does this emotional state represent
      const percentageOfWeight = emotionalStateResult ? weight / totalWeight : 0;

      // Calculate value, i.e. the height of the bar or percentage out of maximum (100)
      const value = percentageOfWeight * (answerPercent * 100);

      return {
        x: d.week.fromDate,
        y: value,
        label: " ",
        emotionalState: emotionalState,
        percentageOfWeight: percentageOfWeight * 100, // How many percent of the total weight does this emotional state represent
      };
    });

    return res;
  };

  return (
    <div>
      <VictoryChart domainPadding={{ x: 20, y: [0, 10] }} padding={{ top: 50, bottom: 50, left: 65, right: 0 }}>
        <VictoryAxis
          label={t("coverage")}
          axisLabelComponent={<VictoryLabel dy={-15} />}
          dependentAxis
          domain={[0, 100]}
          tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          tickFormat={(tick) => `${tick} %`}
          style={{
            axis: {
              stroke: colors.axis,
              transform: "scaleY(1.5) translateY(-20%)", // Scale the axis to fill the height of the chart
            },
            grid: {
              stroke: ({ tick }) => {
                const highlightedTicks = [30, 50, 70, 90];
                return highlightedTicks.includes(tick) ? colors.label.default : colors.axis;
              },
              strokeDasharray: "4",
            },
            axisLabel: { fontSize: 10, fill: colors.label.default },
            tickLabels: { fontSize: 8, fill: colors.label.default },
          }}
        />
        <VictoryAxis
          orientation="top"
          offsetY={50}
          label={t("weekAxisLabel")}
          tickValues={data.map((result) => result.week.fromDate)}
          tickFormat={(tick) => moment(tick).format("WW")}
          tickLabelComponent={<VictoryLabel backgroundPadding={5.25} />}
          style={{
            axis: { stroke: colors.axis },
            grid: { stroke: (datum: any) => (datum.tick === selectedWeekNumber ? colors.label.selected : colors.axis) },
            axisLabel: { fontSize: 10, fill: colors.label.default },
            tickLabels: {
              fontSize: 8,
              fill: (datum: any) => (datum.tick === selectedWeekNumber ? colors.label.selected : colors.label.default),
              cursor: "pointer",
            },
          }}
          events={[
            {
              target: "tickLabels",
              eventHandlers: {
                onClick: () => {
                  return [
                    {
                      target: "tickLabels",
                      mutation: (props) => onClickWeek(props.stringTicks[props.datum - 1]),
                    },
                  ];
                },
              },
            },
          ]}
        />
        <VictoryAxis
          orientation="bottom"
          offsetY={55}
          label={t("assessmentCountLabel")}
          axisLabelComponent={<VictoryLabel dy={10} />}
          tickValues={data.map((result) => result.week.fromDate)}
          tickFormat={(tick) => {
            const count = data.find((result) => result.week.fromDate === tick)?.statistics.resultCount;
            return (count ?? 0).toLocaleString(i18n.language);
          }}
          tickLabelComponent={<VictoryLabel angle={-45} textAnchor="end" />}
          style={{
            axis: { stroke: "none" },
            grid: { stroke: "none" },
            axisLabel: { fontSize: 10, fill: colors.label.default },
            tickLabels: {
              fontSize: 8,
              fill: (datum: any) => (datum.tick === selectedWeekNumber ? colors.label.selected : colors.label.default),
            },
          }}
        />
        {/* Use scatter plot to place repetition icon. 110 makes it place nicely with the scale with coverage */}
        <VictoryScatter
          data={data.map((result) => ({
            x: result.week.fromDate,
            y: result.stuckage.length > 0 ? 110 : 0,
            label: "",
          }))}
          dataComponent={<RepetitionLabel data={data} selectedWeekNumber={selectedWeekNumber} />}
          labelComponent={
            <VictoryTooltip
              constrainToVisibleArea
              flyoutComponent={
                <Flyout>
                  <p>{t("repetitionLegend")}</p>
                </Flyout>
              }
            />
          }
        />
        <VictoryStack
          style={{
            data: { stroke: "white", strokeWidth: 1 },
          }}
        >
          {/* One bar represents one emotional state */}
          {/* One element of data array represents one week */}
          {/* `x` represents week number */}
          {/* `y` represents value (size of the piece of the bar) */}
          {emotionalStates.map((emotionalState) => (
            <VictoryBar
              key={emotionalState.ordinal}
              style={{ data: { fill: emotionalState.color, cursor: "pointer" } }}
              // cornerRadius={{ top: 2 }}
              data={getDataForEmotionalState(data, emotionalState)}
              labelComponent={<VictoryTooltip constrainToVisibleArea flyoutComponent={<EmotionalStateFlyout />} />}
              domain={{ y: [0, 100] }}
            />
          ))}
        </VictoryStack>
      </VictoryChart>
    </div>
  );
};

export default BarStack;

// TODO: Check types
type LabelProps = {
  x?: number;
  y?: number;
  datum?: number | string;
  text?: number | string;
  verticalAnchor?: string;
  textAnchor?: string;
  angle?: number;
  transform?: string;
  style?: object;
  events?: any;
  data: API.HistoryData;
  selectedWeekNumber?: string;
};

const RepetitionLabel = (props: LabelProps) => {
  const iconWidth = 10;

  if (props.data.find((result) => result.week.fromDate === props?.datum.x)?.stuckage?.length ?? 0 > 0) {
    return (
      <g transform={`translate(${(props.x ?? 0) - iconWidth / 2}, ${props.y})`} {...props.events}>
        <InfoIcon
          width={iconWidth}
          style={{
            ...props.style,
            color: props?.datum.x === props.selectedWeekNumber ? colors.label.selected : colors.label.default,
          }}
        />
      </g>
    );
  } else {
    return null;
  }
};

const Container = styled.div`
  display: table;
  margin: auto;
  max-width: 12rem;
`;

const DataContainer = styled.div`
  display: flex;
  margin: auto;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0.5em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 8px;
  color: ${({ color }) => color};

  & > * {
    margin: 0 0.25em;
  }
`;

const Text = styled("span")`
  color: black;
`;

const Flyout = (props: any) => {
  return (
    <foreignObject x={props.x - 220} y={props.y - 20} width="100%" height="100%">
      <Container>
        <DataContainer color={props.color || colors.label.selected}>{props.children}</DataContainer>
      </Container>
    </foreignObject>
  );
};

const EmotionalStateFlyout = ({ x, y, datum }) => {
  const { i18n } = useTranslation();

  const Icon = getIconByOrdinal(datum.emotionalState.ordinal);
  if (!Icon) return null;

  return (
    <Flyout x={x} y={y} color={datum.emotionalState.color}>
      <Icon width={10} height={10} />
      <span>{JSON.parse(datum.emotionalState.name)[i18n.language]}</span>
      <Text>{datum.percentageOfWeight.toFixed(0)}%</Text>
    </Flyout>
  );
};
