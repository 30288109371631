import React from "react";
import { Table, TableBody, Cell, Row, TableHeader } from "../../components/Table";

import { formatName } from "../../utils/format";

import { MutedText } from "../../components/Text";

import { ManageQuery, OrganizationUser } from "../../gql/graphql";
import { useTranslation } from "react-i18next";
import MemberActions from "./MemberActions";

export type Member = {
  startDate: Date;
  endDate: Date | undefined;
  user?: OrganizationUser;
  __typename?: "reportingGroups_members" | undefined;
  userId: string;
};

type MembersTableProps = {
  members: Member[];
  reportingGroup: ManageQuery["reportingGroups"][number];
};

const MembersTable = ({ members, reportingGroup }: MembersTableProps) => {
  const { t } = useTranslation();

  return members.length > 0 ? (
    <Table style={{ marginBottom: "0.75rem" }}>
      <TableHeader>
        <Row>
          <Cell>{t("manageGroups.name")}</Cell>
          <Cell>{t("manageGroups.email")}</Cell>
          <Cell>{t("manageGroups.start")}</Cell>
          <Cell>{t("manageGroups.end")}</Cell>
          <Cell>{t("manageGroups.actions")}</Cell>
        </Row>
      </TableHeader>
      <TableBody>
        {members.map(({ user, startDate, endDate, userId }) => (
          <Row key={userId} muted={endDate && endDate <= new Date()}>
            <Cell>{formatName(user)}</Cell>
            <Cell>{user?.email}</Cell>
            <Cell>{startDate.toLocaleDateString()}</Cell>
            <Cell>{endDate?.toLocaleDateString()}</Cell>
            <Cell>
              {!endDate && user && <MemberActions user={user} members={members} reportingGroup={reportingGroup} />}
            </Cell>
          </Row>
        ))}
      </TableBody>
    </Table>
  ) : (
    <MutedText>{t("manageGroups.noMembers")}</MutedText>
  );
};

export default MembersTable;
