import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { I18n as AmplifyI18n } from "aws-amplify";

// Load translation files
import en from "../translations/en/translation.json";
import fi from "../translations/fi/translation.json";

const USER_LANGUAGE_KEY = "i18nLanguage";

const saveUserLanguage = (lang: string): void => {
  localStorage.setItem(USER_LANGUAGE_KEY, lang);
};

const getUserLanguage = (): string | null => {
  const lang = localStorage.getItem(USER_LANGUAGE_KEY);
  return lang;
};

// Initialize the internationalization library
i18n.use(initReactI18next).init({
  lng: getUserLanguage() ?? "fi",
  fallbackLng: "en",
  saveMissing: true,
  resources: {
    en: { translation: en },
    fi: { translation: fi },
  },
  nsSeparator: false,
  debug: true,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  react: {
    wait: true,
  },
});

AmplifyI18n.putVocabulariesForLanguage("en", { "Log Out": "Log Out", "Sign Up": "Contact Us" });
AmplifyI18n.putVocabulariesForLanguage("fi", {
  "Sign Out": "Kirjaudu ulos",
  "Sign Up": "Ota yhteyttä",
  "Forgot Password": "Unohditko salasanan?",
  "Reset your password": "Palauta salasana",
  Username: "Käyttäjätunnus",
  "Enter your username": "Syötä käyttäjätunnus",
  Password: "Salasana",
  "Enter your password": "Syötä salasana",
  "Sign In": "Kirjaudu",
  Send: "Lähetä",
  "Sign in to your account": "Kirjaudu sisään",
  "Back to Sign In": "Takaisin",
  "Please Sign In / Sign Up": " ",
  "Contact Us": "Ota yhteyttä",
  "Confirmation Code": "Varmistuskoodi",
  "Enter your confirmation code": "Syötä varmistuskoodi",
  "Enter your new password": "Syötä uusi salasanasi",
  Submit: "Tallenna",
  "Change Password": "Vaihda salasana",
  "Password *": "Salasana *",
  "Username *": "Käyttäjätunnus *",
  "Forgot your password?": "Unohditko salasanan?",
  "Reset password": "Ota yhteyttä",
});
AmplifyI18n.setLanguage(getUserLanguage() ?? "fi");

export default i18n;

export { saveUserLanguage };
