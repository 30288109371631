import React from "react";
import { useQuery, gql } from "@apollo/client";

import { Page } from "../../App";
import ReportingGroups from "./ReportingGroups";
import { useTranslation } from "react-i18next";
import { H1, MutedText, Text } from "../../components/Text";
import { ManageQuery } from "../../gql/graphql";

import { ReactComponent as LoadingSpinner } from "../../assets/loading.svg";
import styled from "styled-components";

export const MANAGING_QUERY = gql`
  query Manage {
    reportingGroups {
      id
      name
      reportingGroups_members {
        userId
        startDate
        endDate
      }
    }
    orgUsers {
      organizationId
      users {
        Username
        email
        given_name
        family_name
      }
    }
  }
`;

const Manage = () => {
  const { data, loading, error } = useQuery<ManageQuery>(MANAGING_QUERY);
  const { t } = useTranslation();

  return (
    <Page>
      <H1>{t("manageGroups.title")}</H1>
      <Text>{t("manageGroups.description")}</Text>
      <Text>{t("manageGroups.support")}</Text>
      {loading && (
        <SpinnerContainer>
          <LoadingSpinner />
          <MutedText>{t("manageGroups.loading")}</MutedText>
        </SpinnerContainer>
      )}
      {data && <ReportingGroups data={data} />}
    </Page>
  );
};

const SpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
`;

export default Manage;
