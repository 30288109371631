import React from "react";
import { useTranslation } from "react-i18next";
import Card, { ExpandableCard } from "../../components/Card";

import { useModal } from "../../components/Modal";
import LinkButton from "../../components/LinkButton";

import AddMembers from "./AddMembers";
import MembersTable from "./MembersTable";

import { getLocaleValue } from "../../utils/localization";
import { ManageQuery } from "../../gql/graphql";

type ReportingGroupProps = {
  reportingGroup: ManageQuery["reportingGroups"][number];
  orgUsers: ManageQuery["orgUsers"];
};

const ReportingGroup = ({ reportingGroup, orgUsers }: ReportingGroupProps) => {
  const { t, i18n } = useTranslation();

  const { name, reportingGroups_members } = reportingGroup;

  const { isOpen, toggle } = useModal();
  const { isOpen: cardIsOpen, toggle: cardToggle } = useModal(true);

  const members = reportingGroups_members
    .map(({ startDate, endDate, ...member }) => {
      const user = orgUsers?.users?.find((user) => user.Username === member.userId);

      return { ...member, startDate: new Date(startDate), endDate: endDate ? new Date(endDate) : undefined, user };
    })
    .filter((member) => !!member.user)
    .sort((a, b) => {
      // Ended memberships last
      if (a.endDate && a.endDate < new Date()) return 1;
      if (b.endDate && b.endDate < new Date()) return -1;

      if (!a.user || !a.user.family_name) return 1;
      if (!b.user || !b.user.family_name) return -1;
      return a.user.family_name.localeCompare(b.user.family_name);
    });

  const usersNotInGroup = orgUsers?.users?.filter((user) => !members.find((member) => member.userId === user.Username));

  return (
    <>
      <ExpandableCard
        centered={false}
        isOpen={cardIsOpen}
        toggle={cardToggle}
        actionTitle={cardIsOpen ? t("manageGroups.hideMembers") : t("manageGroups.showMembers")}
        title={t("manageGroups.reportingGroupTitle", { name: getLocaleValue(name, i18n.language) })}
      >
        <MembersTable members={members} reportingGroup={reportingGroup} />
        <LinkButton onClick={toggle}>{t("manageGroups.addMembersButton")}</LinkButton>
      </ExpandableCard>
      <AddMembers
        title={getLocaleValue(name, i18n.language)}
        reportingGroup={reportingGroup}
        usersNotInGroup={usersNotInGroup}
        isOpen={isOpen}
        toggle={toggle}
      />
    </>
  );
};

export default ReportingGroup;
