import * as React from "react";
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

const GoogleAnalytics = ({location}) => {
    React.useEffect(() => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    }, [location])
    
    return ( null )
}

export default withRouter(GoogleAnalytics);