import React from "react";
import ReportingGroup from "./ReportingGroup";
import { useTranslation } from "react-i18next";
import { getLocaleValue } from "../../utils/localization";
import { ManageQuery } from "../../gql/graphql";

type ReportingGroupsProps = {
  data: ManageQuery;
};

const ReportingGroups = ({ data }: ReportingGroupsProps) => {
  const { i18n } = useTranslation();
  const reportingGroups = [...data?.reportingGroups].sort((a, b) =>
    getLocaleValue(a.name, i18n.language).localeCompare(getLocaleValue(b.name, i18n.language))
  );

  return (
    <div>
      {data &&
        reportingGroups.map((reportingGroup) => (
          <ReportingGroup key={reportingGroup.id} reportingGroup={reportingGroup} orgUsers={data.orgUsers} />
        ))}
    </div>
  );
};

export default ReportingGroups;
