import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as OverviewIcon } from "../../assets/overview.svg";
import { ReactComponent as EmotiotionsIcon } from "../../assets/ideas.svg";
import { ReactComponent as TeamsIcon } from "../../assets/teams.svg";

const LinkList = () => {
  const { t } = useTranslation();

  const links = [
    {
      path: "/",
      text: t("overview"),
      Icon: OverviewIcon,
      exact: true,
    },
    {
      path: "/learn",
      text: t("emotions"),
      Icon: EmotiotionsIcon,
    },
    {
      path: "/manage",
      text: t("manage"),
      Icon: TeamsIcon,
    },
  ];

  return (
    <SidebarLinkList>
      {links.map(({ path, text, Icon, exact }) => (
        <SidebarLink key={path} to={path} activeClassName="active" exact={exact}>
          <SidebarLinkContent>
            <Icon />
            <SidebarLinkText>{text}</SidebarLinkText>
          </SidebarLinkContent>
        </SidebarLink>
      ))}
    </SidebarLinkList>
  );
};

export default LinkList;

const SidebarLinkList = styled.ul`
  list-style-type: "none";
  padding: 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const SidebarLink = styled(NavLink)`
  color: #363740;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-decoration: none;

  &:hover {
    color: #363740; // Override default hover color
  }

  &.${(props) => props.activeClassName} {
    background-color: #3637400a;
  }
`;

const SidebarLinkContent = styled.li`
  padding: 1rem;
  display: flex;
  align-items: center;
`;

const SidebarLinkText = styled.span`
  margin-left: 1rem;
`;
